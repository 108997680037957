import { useMutation } from "@tanstack/react-query";
import { supabase } from "../supabase/supabase-client";
import { DateOption, LogeEvent, LogeMember } from "../types/entities";
import { TablesInsert } from "../types/supabase";
import { VoteAnswer } from "./events-api";

export interface AddVotesPayload {
  logeMemberId: LogeMember["id"];
  selectedDates: Record<DateOption["id"], VoteAnswer>;
}

export const useAddVotes = () => {
  return useMutation(async (payload: AddVotesPayload) => {
    const { selectedDates, logeMemberId } = payload;

    const votes: Array<TablesInsert<"votes">> = Object.entries(
      selectedDates
    ).map(([key, data]) => ({
      answer: data,
      date_option_id: Number(key),
      user_id: logeMemberId,
    }));

    const { error } = await supabase.from("votes").insert(votes).select();
    if (error) throw error;
  });
};

export interface ResetVotesForMemberPayload {
  eventId: LogeEvent["id"];
  memberId: LogeMember["id"];
}

export const useResetVotesForMember = () => {
  return useMutation(async (payload: ResetVotesForMemberPayload) => {
    const { eventId, memberId } = payload;

    const { error: dateOptionError, data: dateOptions } = await supabase
      .from("date_options")
      .select("*")
      .eq("loge_event_id", eventId);
    if (dateOptionError) throw dateOptionError;

    const dateOptionIds = dateOptions.map((d) => d.id);

    const { error } = await supabase
      .from("votes")
      .delete()
      .eq("user_id", memberId)
      .in("date_option_id", dateOptionIds);
    if (error) throw error;
  });
};
