import React, { useCallback, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { useNavigate } from "react-router-dom";
import { useAddEvent } from "../api/events-api";
import { queryClient } from "../api/queryClient";
import EventForm, { EventFormValues } from "../components/events/EventForm";

const AddEvent: React.FC = () => {
  const { mutateAsync: addEvent, isLoading } = useAddEvent();
  const navigate = useNavigate();
  const [showError, setShowError] = useState(false);

  const onSubmit = useCallback(
    async (formValues: EventFormValues) => {
      try {
        setShowError(false);
        await addEvent(formValues);

        queryClient.invalidateQueries();

        navigate("/");
      } catch (error) {
        // TODO: Add error handling
        setShowError(true);
      }
    },
    [addEvent, navigate]
  );

  return (
    <div className="p-4 mx-auto max-w-md">
      <h2 className="text-2xl mb-4">Add Event</h2>
      {showError && (
        <div>
          <span className="text-red-500">
            Fejl i formular! Event blev ikke tilføjet
          </span>
        </div>
      )}
      {!isLoading && (
        <EventForm
          onSubmit={onSubmit}
          initialValues={{ isConfirmed: false, showVoteLink: false }}
        />
      )}
    </div>
  );
};

export default AddEvent;
