import { FC } from "react";

const ListPenaltiesPage: FC = () => {
  return (
    <div className="px-4 pt-4 mx-auto max-w-md">
      <h1 className="font-serif font-bold text-3xl">Bøder</h1>
      <div className="grid grid-cols-7 gap-2">{/* TODO: Implement */}</div>
    </div>
  );
};

export default ListPenaltiesPage;
