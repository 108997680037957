import React, { useCallback, useState } from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { useListAllEvents } from "../api/events-api";
import { useSupabaseAuth } from "../supabase/SupabaseContext";
import { supabase } from "../supabase/supabase-client";

const AdminPage: React.FC = () => {
  const { user } = useSupabaseAuth();
  const [signingOut, setSigningOut] = useState(false);
  const navigate = useNavigate();
  const { data: events = [] } = useListAllEvents();

  const handleSignOut = useCallback(() => {
    supabase.auth.signOut();
    setSigningOut(true);
  }, []);

  if (signingOut) {
    return <Navigate to="/" />;
  }

  if (!user && !signingOut) {
    return <Navigate to="/login" />;
  }

  return (
    <div className="mx-auto max-w-md items-center justify-center bg-gray-100">
      <h1 className="text-2xl font-semibold mb-4">Admin Page</h1>
      {/* Render events */}
      <div className="space-y-4 mb-5">
        {events.map((event) => (
          <div
            onClick={() => navigate(`/edit/${event.id}`)}
            key={event.title}
            className="bg-gray-200 p-4 rounded-lg shadow-md"
          >
            <h2 className="text-lg font-semibold">{event.title}</h2>
          </div>
        ))}
      </div>

      {/* Button container */}
      <div className="flex justify-evenly">
        <Link
          to="/"
          className="bg-blue-500 text-white py-2 px-4 rounded-md text-center hover:bg-blue-600 transition duration-300"
        >
          Home
        </Link>
        <button
          onClick={handleSignOut}
          className="bg-red-500 text-white py-2 px-4 rounded-md text-center hover:bg-red-600 transition duration-300"
        >
          Sign out
        </button>
      </div>
    </div>
  );
};

export default AdminPage;
