import dayjs from "dayjs";
import { useCallback, useEffect, useState } from "react";
import { Calendar, DateObject } from "react-multi-date-picker";
import { useNavigate, useParams } from "react-router-dom";
import {
  useAddDateOptions,
  useDateOptionsForEvent,
  useRemoveDateOptions,
} from "../api/date-options";
import { useGetEvent } from "../api/events-api";
import { queryClient } from "../api/queryClient";
import { useLogeMembers } from "../api/user-api";
import { useResetVotesForMember } from "../api/vote-api";
import "../components/votable-events/custom-css/date-picker.css";
import Logo from "../dss.png";
import { LogeMember } from "../types/entities";
import { assertIsDefined } from "../utils/assertion";

const EditVotableEventPage = () => {
  const { eventId } = useParams();
  const [selectedDates, setSelectedDates] = useState<DateObject[]>([]);
  const { data: members } = useLogeMembers();
  const [selectedResetUser, setSelectedResetUser] =
    useState<LogeMember["id"]>();
  const { data: event } = useGetEvent(eventId);
  const { data: dateOptions, isLoading } = useDateOptionsForEvent(eventId);
  const { mutateAsync: addDates } = useAddDateOptions();
  const { mutateAsync: removeDates, isLoading: isUpdating } =
    useRemoveDateOptions();
  const { mutateAsync: resetVotesForMember } = useResetVotesForMember();
  const navigate = useNavigate();

  useEffect(() => {
    setSelectedDates(
      dateOptions?.map((d) => new DateObject({ date: d.date })) ?? []
    );
  }, [dateOptions]);

  const onResetVotes = useCallback(async () => {
    if (!!selectedResetUser && !!eventId) {
      await resetVotesForMember({
        eventId: Number(eventId),
        memberId: selectedResetUser,
      });
    }
    queryClient.invalidateQueries();
  }, [eventId, resetVotesForMember, selectedResetUser]);

  const onUpdateDates = useCallback(async () => {
    assertIsDefined(eventId);
    const removedDates = dateOptions?.filter(
      (dateOption) =>
        !selectedDates.some((selectedDate) =>
          dayjs(selectedDate.format("YYYY-MM-DD")).isSame(
            dateOption.date,
            "day"
          )
        )
    );
    const dateIds = removedDates?.map((d) => d.id) ?? [];

    console.log("dateids", dateIds);

    if (dateIds.length > 0) {
      await removeDates({ dateOptionIds: dateIds });
    }

    const newDates = selectedDates.filter(
      (selectedDate) =>
        !dateOptions?.some((dateOption) =>
          dayjs(selectedDate.format("YYYY-MM-DD")).isSame(
            dateOption.date,
            "day"
          )
        )
    );

    if (newDates.length > 0) {
      await addDates({
        eventId: Number(eventId),
        selectedDates: newDates.map((d) => d.format("YYYY-MM-DD")),
      });
    }

    queryClient.invalidateQueries(["dateOptions"]);
  }, [addDates, dateOptions, eventId, removeDates, selectedDates]);

  if (!event || isLoading || isUpdating) {
    return (
      <div className="px-4 pt-4 mx-auto max-h-md content-center max-w-md">
        <div className="flex justify-center items-center opacity-60 p-6">
          <div className="flex justify-center items-center h-32 w-32 rounded-full bg-gray-200">
            <img src={Logo} alt="Logo" className={`h-20 w-20 animate-spin`} />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="px-4 py-4 mx-auto max-w-md">
      <h1 className="font-serif text-3xl">{event.title}</h1>
      <pre>Reset for: {JSON.stringify(selectedResetUser, null, 2)}</pre>
      <h4 className="text-lg text-gray-600">{event.host}</h4>
      <div
        onClick={() => navigate(`/vote/${eventId}`)}
        className="underline text-teal-700 tracking-wider"
      >
        Afstemning
      </div>

      <div className="mt-3">
        <h3 className="font-bold">Votable dates</h3>
        <Calendar
          weekStartDayIndex={1}
          highlightToday={false}
          multiple
          shadow={false}
          className="rmdp-mobile"
          value={selectedDates}
          onChange={(dates: DateObject[]) => {
            const pureDates = dates.map(
              (date) =>
                new DateObject(
                  dayjs(date.unix * 1000)
                    .startOf("day")
                    .format("YYYY/MM/DD")
                )
            );

            setSelectedDates(pureDates);
          }}
        />
        <button
          className="btn bg-green-300 p-3 mt-3 font-semibold px-5 rounded-lg"
          onClick={onUpdateDates}
        >
          Save
        </button>
      </div>

      <div className="mt-6">
        <h3 className="font-bold">Reset votes</h3>
        <p className="text-sm text-gray-400">Reset for</p>
        <div className="flex">
          <select
            className="select flex-1 mr-3"
            onChange={(e) => {
              const value = e.target.value;
              setSelectedResetUser(Number(value));
            }}
          >
            <option value="" disabled selected>
              Vælg
            </option>
            {members?.map((member) => (
              <option key={member.id} value={member.id}>
                {member.name}
              </option>
            ))}
          </select>
          <button
            onClick={onResetVotes}
            className="button rounded bg-red-300 p-2 text-gray-100"
          >
            Reset
          </button>
        </div>
      </div>
    </div>
  );
};

export default EditVotableEventPage;
