import { FC } from "react";
import { useNavigate } from "react-router-dom";
import Number from "../components/FlipCalendarNumber";
import EventCard from "../components/events/EventCard";
import { useSupabaseAuth } from "../supabase/SupabaseContext";
import { LogeEvent } from "../types/entities";
import { getRandomColorPair } from "../utils/colors/colorPairs";
import { findUpcomingDate, groupEventsByYear } from "../utils/event-utils";

interface EventsPageProps {
  events: Array<LogeEvent>;
  loading: boolean;
}

const EventsPage: FC<EventsPageProps> = ({ events, loading }) => {
  const nextDate = findUpcomingDate(events);
  const eventSections = groupEventsByYear(events);
  const { user } = useSupabaseAuth();
  const navigate = useNavigate();

  return (
    <div className="container pt-1">
      <Number date={nextDate} loading={loading} />
      {eventSections.map((section, index) => (
        <div key={index}>
          <div className="tracking-wide text-slate-500 mb-2 text-xl text-right ">
            {section.title}
          </div>
          <div>
            {section.items.map((event) => (
              <EventCard
                key={event.id}
                event={event}
                colorPair={getRandomColorPair()}
              />
            ))}
          </div>
        </div>
      ))}
      {!!user && !loading && (
        <div>
          <button
            className="w-full rounded border-dashed border-2 border-slate-300 py-4 px-3 text-slate-400"
            onClick={() => navigate("/event/add")}
          >
            Tilføj event
          </button>
        </div>
      )}
    </div>
  );
};

export default EventsPage;
