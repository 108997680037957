import dayjs from "dayjs";
import { useCallback, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  DateOptionsWithVotes,
  useDateOptionsForEvent,
} from "../api/date-options";
import { VoteAnswer, useGetEvent } from "../api/events-api";
import { queryClient } from "../api/queryClient";
import { useLogeMembers } from "../api/user-api";
import { useAddVotes } from "../api/vote-api";
import AvatarCircle from "../components/Avatar";
import DateOptionCard from "../components/votable-events/DateOptionCard";
import Logo from "../dss.png";
import { useSupabaseAuth } from "../supabase/SupabaseContext";
import { DateOption, LogeMember } from "../types/entities";
import { assertIsDefined } from "../utils/assertion";

const VotableEventPage = () => {
  const { eventId } = useParams();
  const { data: event } = useGetEvent(eventId);
  const navigate = useNavigate();
  const { user } = useSupabaseAuth();
  const [selectedUser, setSelectedUser] = useState<LogeMember["id"]>();
  const { data: members } = useLogeMembers();
  const { mutateAsync: addVotes } = useAddVotes();
  const { data: dateOptions } = useDateOptionsForEvent(eventId);
  const datesByMonth = new Map<string, DateOptionsWithVotes>();
  const [selectedDates, setSelectedDates] = useState<
    Record<DateOption["id"], VoteAnswer>
  >({});

  const selectableMembers = useMemo(() => {
    if (!dateOptions) return members;
    const votes = dateOptions.flatMap((option) => option.votes);
    return members?.filter(
      (member) => !votes.some((vote) => vote?.user_id === member.id)
    );
  }, [dateOptions, members]);

  const onUserClickHandler = useCallback((member: LogeMember) => {
    setSelectedUser(member.id);
  }, []);

  const onSave = useCallback(async () => {
    assertIsDefined(selectedUser);
    await addVotes({
      logeMemberId: selectedUser,
      selectedDates,
    });

    setSelectedDates({});
    setSelectedUser(undefined);
    queryClient.invalidateQueries();
    navigate(`/vote/${eventId}/result`);
  }, [addVotes, eventId, navigate, selectedDates, selectedUser]);

  const toggleSelect = (option: DateOption["id"]) => {
    if (Object.keys(selectedDates).includes(option.toString())) {
      const answer = selectedDates[option];
      if (answer === VoteAnswer.YES) {
        // change to maybe
        const newSelectedDates = {
          ...selectedDates,
          [option]: VoteAnswer.MAYBE,
        };
        setSelectedDates(newSelectedDates);
      } else if (answer === VoteAnswer.MAYBE) {
        const newSelectedDates = { ...selectedDates };
        delete newSelectedDates[option];
        setSelectedDates(newSelectedDates);
      }
    } else {
      const newSelectedDates = {
        ...selectedDates,
        [option]: VoteAnswer.YES,
      };
      setSelectedDates(newSelectedDates);
    }
  };

  dateOptions?.forEach((option) => {
    const monthYear = dayjs(option.date).format("MMMM YYYY");
    if (!datesByMonth.has(monthYear)) {
      datesByMonth.set(monthYear, []);
    }
    datesByMonth.get(monthYear)?.push(option);
  });

  if (!event) {
    return (
      <div className="px-4 pt-4 mx-auto max-h-md content-center max-w-md">
        <div className="flex justify-center items-center opacity-60 p-6">
          <div className="flex justify-center items-center h-32 w-32 rounded-full bg-gray-200">
            <img src={Logo} alt="Logo" className={`h-20 w-20 animate-spin`} />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="px-4 pt-4 mx-auto max-w-md">
      <button className="text-xs text-gray-400" onClick={() => navigate("/")}>
        ‹ Tilbage
      </button>
      <h1 className="font-serif font-bold text-3xl">{event.title}</h1>
      <div
        onClick={() => navigate(`/vote/${eventId}/result`)}
        className="mb-1 underline text-teal-700 tracking-wider"
      >
        Se resultat
      </div>
      {!!user && (
        <button
          style={{ position: "absolute", top: 5, right: 5 }}
          className="bg-sky-300 rounded-md px-4 py-3"
          onClick={() => {
            navigate(`/vote/${eventId}/edit`);
          }}
        >
          Edit
        </button>
      )}
      <div className="mt-6">
        <div className="text-lg text-gray-700 pb-2">Hvem er du?</div>
        <div className="grid grid-cols-7 gap-2">
          {selectableMembers?.map((member) => (
            <AvatarCircle
              key={member.id}
              userId={member.id}
              onClick={() => onUserClickHandler(member)}
              selected={selectedUser === member.id}
              style={{
                transition: "0.3s",
                transform:
                  selectedUser === member.id ? "scale(1.2)" : "scale(1)",
                opacity: !!selectedUser && selectedUser !== member.id ? 0.4 : 1,
              }}
            />
          ))}
        </div>
      </div>
      <div
        className="pt-7 pb-5 transition  duration-500"
        style={{
          opacity: selectedUser ? 1 : 0.23,
          pointerEvents: selectedUser ? "auto" : "none",
        }}
      >
        <div className="text-lg text-gray-700 pb-2">
          Vælg de datoer du kan deltage
        </div>
        {Array.from(datesByMonth.keys()).map((monthYear) => (
          <div key={monthYear}>
            <h2 className="text-xs font-semibold pb-1 uppercase text-gray-400">
              {monthYear}
            </h2>
            <div className="grid grid-cols-1 gap-2 pb-4">
              {datesByMonth.get(monthYear)?.map((dateOption) => (
                <DateOptionCard
                  key={dateOption.id}
                  date={dateOption.date}
                  votes={dateOption.votes}
                  onClick={() => toggleSelect(dateOption.id)}
                  answer={selectedDates[dateOption.id]}
                />
              ))}
            </div>
          </div>
        ))}
      </div>
      <div className="mb-12">
        <button
          disabled={!selectedUser}
          className={`rounded-full bg-teal-600 w-full hover:bg-teal-700 text-white font-semibold py-3 px-4 ${
            !selectedUser ? "opacity-50 cursor-not-allowed" : ""
          }`}
          onClick={onSave}
        >
          Gem
        </button>
      </div>
    </div>
  );
};

export default VotableEventPage;
