import { BrowserRouter, Route, Routes } from "react-router-dom";

import RequireAuth from "./components/RequireAuth";
import ScrollToTop from "./components/ScrollToTop";
import AddEvent from "./pages/AddEvent";
import AdminPage from "./pages/AdminPage";
import EditEvent from "./pages/EditEvent";
import EditVotableEventPage from "./pages/EditVotableEventPage";
import HomePage from "./pages/HomePage";
import ListPenaltiesPage from "./pages/ListPenaltiesPage";
import LoginPage from "./pages/LoginPage";
import VotableEventPage from "./pages/VotableEventPage";
import VotableEventResultPage from "./pages/VotableEventResultPage";

function App() {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/penalties" element={<ListPenaltiesPage />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/vote/:eventId" element={<VotableEventPage />} />
        <Route
          path="/vote/:eventId/result"
          element={<VotableEventResultPage />}
        />
        <Route
          path="/vote/:eventId/edit"
          element={
            <RequireAuth>
              <EditVotableEventPage />
            </RequireAuth>
          }
        />
        <Route
          path="/edit"
          element={
            <RequireAuth>
              <AdminPage />
            </RequireAuth>
          }
        />
        <Route
          path="/edit/:eventId"
          element={
            <RequireAuth>
              <EditEvent />
            </RequireAuth>
          }
        />

        <Route
          path="/event/add"
          element={
            <RequireAuth>
              <AddEvent />
            </RequireAuth>
          }
        />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
