import { FC, Fragment, PropsWithChildren } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useSupabaseAuth } from "../supabase/SupabaseContext";

const RequireAuth: FC<PropsWithChildren> = ({ children }) => {
  const { user } = useSupabaseAuth();
  const location = useLocation();

  if (!user) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return <Fragment>{children}</Fragment>;
};

export default RequireAuth;
