import { useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDateOptionsForEvent } from "../api/date-options";
import { useGetEvent } from "../api/events-api";
import DateOptionCard from "../components/votable-events/DateOptionCard";
import Logo from "../dss.png";
import { useSupabaseAuth } from "../supabase/SupabaseContext";
import { countVotes } from "../utils/event-utils";

type SortingOption = "date" | "popularity";

const VotableEventResultPage = () => {
  const { eventId } = useParams();
  const { data: event } = useGetEvent(eventId);
  const navigate = useNavigate();
  const [selectedSorting, setSelectedSorting] =
    useState<SortingOption>("popularity");
  const { user } = useSupabaseAuth();
  const { data: dateOptions = [] } = useDateOptionsForEvent(eventId);

  const sortedDateOptions = useMemo(() => {
    switch (selectedSorting) {
      case "date":
        return dateOptions.sort(
          (a, b) => new Date(a.date).getTime() - new Date(b.date).getTime()
        );
      case "popularity":
        return dateOptions.sort(
          (a, b) => countVotes(b.votes) - countVotes(a.votes)
        );
    }
  }, [dateOptions, selectedSorting]);

  if (!event) {
    return (
      <div className="px-4 pt-4 mx-auto max-h-md content-center max-w-md">
        <div className="flex justify-center items-center opacity-60 p-6">
          <div className="flex justify-center items-center h-32 w-32 rounded-full bg-gray-200">
            <img src={Logo} alt="Logo" className={`h-20 w-20 animate-spin`} />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="px-4 pt-4 mx-auto max-w-md">
      <button className="text-xs text-gray-400" onClick={() => navigate("/")}>
        ‹ Hjem
      </button>
      <h1 className="font-serif font-bold text-3xl">{event.title}</h1>
      {!!user && (
        <button
          className="bg-sky-300 rounded-md px-4 py-3"
          style={{ position: "absolute", top: 5, right: 5 }}
          onClick={() => {
            navigate(`/vote/${eventId}/edit`);
          }}
        >
          Edit
        </button>
      )}
      <div
        onClick={() => navigate(`/vote/${eventId}`)}
        className="underline text-teal-700 tracking-wider"
      >
        Afstemning
      </div>

      <div className="pt-6 pb-5">
        <div className="pb-2">
          <div className="text-md text-gray-700">Sortér efter</div>
          <div className="flex">
            <button
              className={`${
                selectedSorting === "popularity"
                  ? "font-semibold underline"
                  : ""
              }
            `}
              onClick={() => setSelectedSorting("popularity")}
            >
              Flest stemmer
            </button>
            <div className="w-5 text-center">|</div>
            <button
              className={`${
                selectedSorting === "date" ? "font-semibold underline" : ""
              }`}
              onClick={() => setSelectedSorting("date")}
            >
              Dato
            </button>
          </div>
        </div>

        <div className="grid grid-cols-1 gap-2 pb-4">
          {sortedDateOptions.map((dateOption) => (
            <DateOptionCard
              showVotes
              key={dateOption.id}
              date={dateOption.date}
              votes={dateOption.votes}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default VotableEventResultPage;
