import { useMutation, useQuery } from "@tanstack/react-query";
import dayjs from "dayjs";
import { EventFormValues } from "../components/events/EventForm";
import { supabase } from "../supabase/supabase-client";
import { LogeEvent, Participant } from "../types/entities";

export enum VoteAnswer {
  YES = 1,
  MAYBE = 0.5,
  NO = 0,
}

export const useListAllEvents = () => {
  const getAllEvents = async () => {
    const { data, error } = await supabase
      .from("loge_events")
      .select("*")
      .gte("date", dayjs().startOf("day").toISOString());
    if (error) throw error;
    return data;
  };

  return useQuery({
    queryKey: ["events"],
    queryFn: getAllEvents,
    select: (data) => {
      return data.sort((a, b) => {
        return new Date(a.date).getTime() - new Date(b.date).getTime();
      });
    },
  });
};

export const useGetEventParticipants = (eventId: number) => {
  const getParticipants = async () => {
    const { data, error } = await supabase
      .from("loge_event_participants")
      .select("*")
      .eq("loge_event_id", eventId);
    if (error) throw error;
    return data as Participant[];
  };

  return useQuery({
    queryKey: ["event_participants", eventId],
    queryFn: getParticipants,
  });
};

export const useGetEvent = (eventId?: string) => {
  const getEvent = async () => {
    if (!!eventId) {
      const { data, error } = await supabase
        .from("loge_events")
        .select("*")
        .eq("id", eventId);
      if (error) throw error;
      return data[0];
    }
    return undefined;
  };

  const EVENT_QUERY_KEY = ["event", eventId];
  return useQuery({
    queryKey: EVENT_QUERY_KEY,
    queryFn: getEvent,
    enabled: !!eventId,
  });
};

interface UpdateEventPayload {
  eventId: LogeEvent["id"];
  formValues: EventFormValues;
}

export const useUpdateEvent = () => {
  return useMutation(async (payload: UpdateEventPayload) => {
    const { eventId, formValues } = payload;
    const { error } = await supabase
      .from("loge_events")
      .update({
        date: formValues.date,
        emoji: formValues.emoji,
        host: formValues.host,
        is_confirmed: formValues.isConfirmed,
        title: formValues.title,
        show_vote_link: formValues.showVoteLink,
      })
      .eq("id", eventId)
      .select();
    if (error) throw error;
  });
};

export const useAddEvent = () => {
  return useMutation(async (formValues: EventFormValues) => {
    const { error } = await supabase
      .from("loge_events")
      .insert([
        {
          date: formValues.date,
          emoji: formValues.emoji,
          title: formValues.title,
          is_confirmed: formValues.isConfirmed,
          host: formValues.host,
          show_vote_link: formValues.showVoteLink,
        },
      ])
      .select();
    if (error) throw error;
  });
};

export const useDeleteEvent = () => {
  return useMutation(async (eventId: number) => {
    const { error } = await supabase
      .from("loge_events")
      .update({ deleted: true })
      .eq("id", eventId);
    if (error) throw error;
  });
};
