import { FC } from "react";
import DatePicker from "react-datepicker";
import { Field, Form } from "react-final-form";
import { useNavigate } from "react-router-dom";
import { useLogeMembers } from "../../api/user-api";
import { LogeEvent } from "../../types/entities";
import { ColorPairName, ColorPairs } from "../../utils/colors/colorPairs";

export interface EventFormValues {
  title: string;
  emoji: string;
  date: string;
  isConfirmed: boolean;
  showVoteLink: boolean;
  colorPairName: ColorPairName;
  host: LogeEvent["host"];
}

interface EventFormProps {
  onSubmit: (values: EventFormValues) => void;
  initialValues?: Partial<EventFormValues>;
}

const EventForm: FC<EventFormProps> = ({ onSubmit, initialValues }) => {
  const navigate = useNavigate();
  const { data: members } = useLogeMembers();
  return (
    <Form<EventFormValues>
      initialValues={initialValues}
      onSubmit={onSubmit}
      render={({ handleSubmit, values }) => (
        <form onSubmit={handleSubmit}>
          <pre>{JSON.stringify(values, null, 2)}</pre>
          <div className="flex justify-center">
            <div
              className="flex  items-center bg-blue-300 justify-center rounded-full w-16 h-16"
              style={
                values.colorPairName
                  ? {
                      background: `linear-gradient(to bottom right, ${
                        ColorPairs[values.colorPairName].color1
                      }80, ${ColorPairs[values.colorPairName].color2}80)`,
                    }
                  : undefined
              }
            >
              <span className="text-3xl">{values.emoji}</span>
            </div>
          </div>
          <div className="mb-4">
            <label className="block text-xs text-slate-500">Title</label>
            <Field name="title">
              {(props) => (
                <input
                  type="text"
                  className="w-full border rounded py-2 px-3"
                  {...props.input}
                />
              )}
            </Field>
          </div>
          <div className="mb-4">
            <label className="block text-xs text-slate-500">Vært</label>
            <Field name="host">
              {(props) => (
                <select
                  className="mt-1 block w-full p-2 border rounded-md"
                  {...props.input}
                >
                  <option disabled>Vælg</option>
                  {members?.map((option) => (
                    <option key={option.id} value={Number(option.id)}>
                      {option.name}
                    </option>
                  ))}
                </select>
              )}
            </Field>
          </div>
          <div className="mb-4">
            <label className="block text-xs text-slate-500">Date</label>
            <Field name="date">
              {(props) => (
                <DatePicker
                  selected={
                    props.input.value ? new Date(props.input.value) : new Date()
                  }
                  onChange={props.input.onChange}
                  dateFormat="yyyy-MM-dd"
                  className="w-full border rounded py-2 px-3"
                />
              )}
            </Field>
          </div>
          <div className="mb-4 flex items-center">
            <label className="block text-xs text-slate-500">Confirmed</label>
            <Field name="isConfirmed" type="checkbox">
              {(props) => (
                <input
                  type="checkbox"
                  className="mx-2 w-7 h-7"
                  {...props.input}
                />
              )}
            </Field>
          </div>
          <div className="mb-4 flex items-center">
            <label className="block text-xs text-slate-500">
              Show vote link
            </label>
            <Field name="showVoteLink" type="checkbox">
              {(props) => (
                <input
                  type="checkbox"
                  className="mx-2 w-7 h-7"
                  {...props.input}
                />
              )}
            </Field>
          </div>
          <div className="mb-4">
            <label className="block text-xs text-slate-500">Emoji</label>
            <Field name="emoji">
              {(props) => (
                <input
                  type="text"
                  className="w-full border rounded py-2 px-3"
                  {...props.input}
                />
              )}
            </Field>
          </div>
          <div className="mb-4">
            <label className="block text-xs text-slate-500 mb-1">
              Background
            </label>
            <Field name="colorPairName">
              {(props) => (
                <div className="grid grid-cols-9 gap-2">
                  {Object.keys(ColorPairs).map((colorPairName) => (
                    <div className="flex justify-center" key={colorPairName}>
                      <div
                        onClick={() => props.input.onChange(colorPairName)}
                        className={`w-8 h-8 bg-blue-400 rounded ${
                          values.colorPairName === colorPairName
                            ? "border-solid border-2 border-sky-500"
                            : ""
                        } `}
                        style={{
                          background: `linear-gradient(to bottom right, ${
                            ColorPairs[colorPairName as ColorPairName].color1
                          }80, ${
                            ColorPairs[colorPairName as ColorPairName].color2
                          }80)`,
                        }}
                      />
                    </div>
                  ))}
                </div>
              )}
            </Field>
          </div>
          <div className="flex justify-between">
            <button
              className="rounded text-white rounded py-2 px-4 bg-slate-600"
              onClick={() => {
                navigate(-1);
              }}
            >
              Back
            </button>
            <button
              type="submit"
              className="bg-green-400 text-white rounded py-2 px-4"
            >
              Save
            </button>
          </div>
        </form>
      )}
    />
  );
};

export default EventForm;
