import dayjs from "dayjs";
import { FC } from "react";
import { VoteAnswer } from "../../api/events-api";
import { Vote } from "../../types/entities";
import { countVotes } from "../../utils/event-utils";
import AvatarCircle from "../Avatar";

interface Props {
  date: string;
  answer?: VoteAnswer;
  showVotes?: boolean;
  onClick?: () => void;
  votes?: Vote[];
}

const DateOptionCard: FC<Props> = ({
  date,
  votes,
  answer = VoteAnswer.NO,
  onClick,
  showVotes = false,
}) => {
  return (
    <div
      className={`flex items-center h-16 relative bg-white p-4 rounded-lg border-2 cursor-pointer select-none transition ${
        answer === VoteAnswer.YES
          ? "border-green-400"
          : answer === VoteAnswer.MAYBE
          ? "border-yellow-400"
          : "border-gray-200"
      }`}
      onClick={onClick}
    >
      <div className="text-center w-8 mr-4">
        <div
          className="text-xs text-gray-500"
          style={{ lineHeight: 0.7, letterSpacing: 1, paddingTop: 6 }}
        >
          {dayjs(date).format("MMM").toUpperCase().substring(0, 3)}
        </div>
        <div className="text-2xl font-bold">{dayjs(date).format("DD")}</div>
      </div>

      <div className="flex-1 mr-2">
        <div className="uppercase text-sm font-medium text-gray-600">
          {dayjs(date).format("ddd D MMMM YYYY")}
        </div>
        {!!votes && (
          <div className="flex">
            {votes.map((vote) => (
              <AvatarCircle
                bgColor={vote.answer === VoteAnswer.YES ? "green" : "yellow"}
                key={vote.user_id}
                size="xs"
                userId={vote.user_id}
                showName={false}
                className="mr-1"
              />
            ))}
          </div>
        )}
      </div>
      {showVotes ? (
        <div className="text-sm text-gray-500">{countVotes(votes)} stemmer</div>
      ) : (
        <div
          className={`${
            answer !== VoteAnswer.NO ? "opacity-1" : "opacity-0"
          } transition `}
        >
          <div
            className={`${
              answer === VoteAnswer.YES ? "bg-green-400" : "bg-yellow-400"
            } text-white w-8 h-8 rounded-full flex items-center justify-center`}
          >
            &#10003;
          </div>
        </div>
      )}
    </div>
  );
};

export default DateOptionCard;
