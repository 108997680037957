import dayjs from "dayjs";
import { reduce } from "lodash";
import { LogeEvent, Vote } from "../types/entities";

export const findUpcomingDate = (events: LogeEvent[]) => {
  const now = dayjs();

  const upcomingEvent = events.reduce(
    (upcoming: LogeEvent | null, currentEvent: LogeEvent) => {
      const currentEventTime = dayjs(currentEvent.date);

      if (
        currentEventTime.isAfter(now) &&
        (!upcoming || currentEventTime.isBefore(dayjs(upcoming.date)))
      ) {
        return currentEvent;
      }

      return upcoming;
    },
    null
  );

  return upcomingEvent ? upcomingEvent.date : undefined;
};

export interface ListSection<T = unknown> {
  title: string;
  items: Array<T>;
}

export const groupEventsByYear = (events: Array<LogeEvent>) => {
  const eventsByYear = events.reduce<Record<number, Array<LogeEvent>>>(
    (grouped, event) => {
      const eventYear = dayjs(event.date).year();

      if (!grouped[eventYear]) {
        grouped[eventYear] = [];
      }

      grouped[eventYear].push(event);

      return grouped;
    },
    {}
  );

  return Object.entries(eventsByYear).map(([year, events]) => ({
    title: year,
    items: events,
  }));
};

export const countVotes = (votesArray: Array<Vote> | undefined) => {
  if (!votesArray) return 0;
  return reduce(votesArray, (prev, curr) => prev + curr.answer, 0);
};
