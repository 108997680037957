export interface ColorPair {
  color1: string;
  color2: string;
}

export type ColorPairName =
  | "OceanBlue"
  | "Sanguine"
  | "LusciousLime"
  | "PurpleLake"
  | "Piglet"
  | "Kashmir"
  | "GreenBeach"
  | "BloodyMary"
  | "SweetMorning"
  | "Quepal"
  | "Cactus"
  | "Yosemite"
  | "Antarctica"
  | "Exotic"
  | "Toxic"
  | "Orbit"
  | "NoMans"
  | "Celestial"
  | "CleanMirror"
  | "MountainRock"
  | "EternalConstance"
  | "JuicyPeach"
  | "WinterNeva"
  | "PlumPlate"
  | "EverlastingSky";

export const ColorPairs: Record<string, ColorPair> = {
  OceanBlue: {
    color1: "#2E3192",
    color2: "#1BFFFF",
  },
  Sanguine: {
    color1: "#D4145A",
    color2: "#FBB03B",
  },
  LusciousLime: {
    color1: "#009245",
    color2: "#FCEE21",
  },
  PurpleLake: {
    color1: "#662D8C",
    color2: "#ED1E79",
  },
  Piglet: {
    color1: "#EE9CA7",
    color2: "#FFDDE1",
  },
  Kashmir: {
    color1: "#614385",
    color2: "#516395",
  },
  GreenBeach: {
    color1: "#02AABD",
    color2: "#00CDAC",
  },
  BloodyMary: {
    color1: "#FF512F",
    color2: "#DD2476",
  },
  SweetMorning: {
    color1: "#FF5F6D",
    color2: "#FFC371",
  },
  Quepal: {
    color1: "#11998E",
    color2: "#38EF7D",
  },
  Cactus: {
    color1: "#C6EA8D",
    color2: "#FE90AF",
  },
  Yosemite: {
    color1: "#EA8D8D",
    color2: "#A890FE",
  },
  Antarctica: {
    color1: "#D8B5FF",
    color2: "#1EAE98",
  },
  Exotic: {
    color1: "#FF61D2",
    color2: "#FE9090",
  },
  Toxic: {
    color1: "#BFF098",
    color2: "#6FD6FF",
  },
  Orbit: {
    color1: "#4E65FF",
    color2: "#92EFFD",
  },
  NoMans: {
    color1: "#A9F1DF",
    color2: "#FFBBBB",
  },
  Celestial: {
    color1: "#C33764",
    color2: "#1D2671",
  },
  CleanMirror: {
    color1: "#93A5CF",
    color2: "#E4EfE9",
  },
  MountainRock: {
    color1: "#868F96",
    color2: "#596164",
  },
  EternalConstance: {
    color1: "#09203F",
    color2: "#537895",
  },
  JuicyPeach: {
    color1: "#FFECD2",
    color2: "#FCB69F",
  },
  WinterNeva: {
    color1: "#A1C4FD",
    color2: "#C2E9FB",
  },
  PlumPlate: {
    color1: "#764BA2",
    color2: "#667EEA",
  },
  EverlastingSky: {
    color1: "#FDFCFB",
    color2: "#E2D1C3",
  },
};

export const getRandomColorPair = () => {
  const allColorPairs = Object.values(ColorPairs);
  const randomIndex = Math.floor(Math.random() * allColorPairs.length);
  return allColorPairs[randomIndex];
};
