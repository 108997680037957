import { QueryData } from "@supabase/supabase-js";
import { UseQueryOptions, useMutation, useQuery } from "@tanstack/react-query";
import { supabase } from "../supabase/supabase-client";
import { DateOption } from "../types/entities";
import { TablesInsert } from "../types/supabase";

const dateOptionsWithVotesQuery = supabase.from("date_options").select(`
  id,
  loge_event_id,
  date,
  votes (
    id,
    date_option_id,
    user_id,
    answer,
    deleted
  )
`);

export type DateOptionsWithVotes = QueryData<typeof dateOptionsWithVotesQuery>;

const getOptionsWithVotes = async (eventId: string | undefined) => {
  const query = supabase
    .from("date_options")
    .select(
      `
    id,
    loge_event_id,
    date,
    votes (
      id,
      date_option_id,
      user_id,
      answer,
      deleted
    )
  `
    )
    .eq("loge_event_id", Number(eventId))
    .neq("deleted", true);

  const { data, error } = await query;
  if (error) throw error;
  const dateOptionsWithVotes: DateOptionsWithVotes = data;
  return dateOptionsWithVotes;
};

export const useDateOptionsForEvent = (
  eventId?: string,
  queryOptions?: UseQueryOptions<DateOptionsWithVotes>
) => {
  return useQuery({
    queryKey: ["dateOptions", eventId],
    queryFn: () => getOptionsWithVotes(eventId),
    select: (data) =>
      data.sort(
        (a, b) => new Date(a.date).getTime() - new Date(b.date).getTime()
      ),
    ...queryOptions,
  });
};

export type DateString = string;

export interface AddDateOptionsPayload {
  eventId: number;
  selectedDates: Array<DateString>;
}

export const useAddDateOptions = () => {
  return useMutation(async (payload: AddDateOptionsPayload) => {
    const { eventId, selectedDates } = payload;

    const newDateOptions: Array<TablesInsert<"date_options">> =
      selectedDates.map((date) => ({
        date,
        loge_event_id: eventId,
      }));

    const { error } = await supabase
      .from("date_options")
      .insert(newDateOptions)
      .select();
    if (error) throw error;
  });
};

export interface DeleteDateOptionsPayload {
  dateOptionIds: Array<DateOption["id"]>;
}

export const useRemoveDateOptions = () => {
  return useMutation(async (payload: DeleteDateOptionsPayload) => {
    const { dateOptionIds } = payload;

    const { error } = await supabase
      .from("date_options")
      .update({ deleted: true })
      .in("id", dateOptionIds);
    if (error) throw error;
  });
};
