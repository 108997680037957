import dayjs from "dayjs";
import da from "dayjs/locale/da";
import localizedFormat from "dayjs/plugin/localizedFormat";
import { FC, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { VoteAnswer, useGetEventParticipants } from "../../api/events-api";
import { useLogeMembers } from "../../api/user-api";
import { useSupabaseAuth } from "../../supabase/SupabaseContext";
import { LogeEvent } from "../../types/entities";
import { ColorPair } from "../../utils/colors/colorPairs";
import AvatarCircle from "../Avatar";

dayjs.locale(da);
dayjs.extend(localizedFormat);

interface EventCardProps {
  event: LogeEvent;
  colorPair: ColorPair;
}

const EventCard: FC<EventCardProps> = ({ event, colorPair }) => {
  const { id, date, is_confirmed, host, title, emoji } = event;
  const { data: members } = useLogeMembers();
  const { user } = useSupabaseAuth();
  const navigate = useNavigate();
  const { data: participants } = useGetEventParticipants(id);

  const onEmojiClick = useCallback(() => {
    if (!!user) {
      navigate(`/edit/${id}`);
    }
    return;
  }, [id, navigate, user]);

  return (
    <div className="bg-white rounded-lg flex-col mb-5">
      <div className="flex">
        <div
          className="flex  rounded-l-lg p-3 flex-col justify-center mr-1"
          style={{ width: 70 }}
        >
          <div className="flex justify-center flex-col text-center">
            {!is_confirmed ? (
              <div
                className="text-2xl font-bold transform rotate-90"
                // style={{ transform: "rotate(44deg)" }}
              >
                {"TBD"}
              </div>
            ) : (
              <div>
                <div
                  className="text-xs"
                  style={{ lineHeight: 1, letterSpacing: 3 }}
                >
                  {dayjs(date).format("MMM").toUpperCase().substring(0, 3)}
                </div>
                <div className="text-3xl font-bold">
                  {dayjs(date).format("DD")}
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="flex flex-grow flex-col py-5">
          <h2 className="text-lg font-bold mb-1" style={{ lineClamp: 1 }}>
            {title}
          </h2>
          {event.show_vote_link && (
            <div
              onClick={() => navigate(`/vote/${id}`)}
              className="mb-1 underline text-teal-700 tracking-wider"
            >
              Afstemning
            </div>
          )}
          <p className="text-gray-500 text-sm mb-1">
            {members?.find((member) => member.id === host)?.name}
          </p>
          <p className="text-gray-500 text-sm">
            {!is_confirmed ? (
              <span>TBD</span>
            ) : (
              dayjs(date).format("dddd [d.] LL")
            )}
          </p>
          <div className="flex flex-row mt-2">
            {participants?.map((participant) => (
              <AvatarCircle
                size="xs"
                showName={false}
                userId={participant.user_id}
                className="pr-2"
                bgColor={
                  participant.answer === VoteAnswer.YES ? "green" : "yellow"
                }
              />
            ))}
          </div>
        </div>
        <div className="flex flex-col justify-center pr-5">
          <div
            onClick={onEmojiClick}
            className="flex items-center justify-center rounded-full w-12 h-12"
            style={{
              background: `linear-gradient(to bottom right, ${colorPair.color1}80, ${colorPair.color2}80)`,
            }}
          >
            <span className="text-2xl">{emoji}</span>
          </div>
        </div>
      </div>
    </div>
  );
};
export default EventCard;
