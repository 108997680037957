import { FC } from "react";
import { Link } from "react-router-dom";
import { useListAllEvents } from "../api/events-api";
import Logo from "../dss.png";
import { useSupabaseAuth } from "../supabase/SupabaseContext";
import Events from "./Events";

const title = "Det Sydsvenske Spejderkorps";

const HomePage: FC = () => {
  const { user } = useSupabaseAuth();
  const { data: events = [], isLoading } = useListAllEvents();
  return (
    <div className="container mx-auto p-4 max-w-md">
      <div className="flex items-center py-4 pb-6">
        <h1 className="text-5xl font-extrabold text-gray-700 font-serif">
          {title}
        </h1>
      </div>
      <Events events={events} loading={isLoading} />
      <div className="flex justify-center items-center opacity-60 p-6">
        <div className="flex justify-center items-center h-32 w-32 rounded-full bg-gray-200">
          <img
            src={Logo}
            alt="Logo"
            className={`h-20 w-20 ${isLoading ? "animate-spin" : ""}`}
          />
        </div>
      </div>
      <div className="text-center mt-4 mb-3 flex flex-col">
        <Link to="/edit" className="text-sm text-gray-500 hover:text-blue-500">
          Admin
        </Link>
        {!!user && (
          <span className="text-xs text-slate-400 tracking-wider">
            {user.email}
          </span>
        )}
      </div>
    </div>
  );
};

export default HomePage;
