import { QueryClientProvider } from "@tanstack/react-query";
import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { queryClient } from "./api/queryClient";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { SupabaseProvider } from "./supabase/SupabaseContext";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <QueryClientProvider client={queryClient}>
    <React.StrictMode>
      <SupabaseProvider>
        <App />
      </SupabaseProvider>
      {/* <ReactQueryDevtools initialIsOpen={true} /> */}
    </React.StrictMode>
  </QueryClientProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
