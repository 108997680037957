import { User } from "@supabase/supabase-js";
import React, {
  PropsWithChildren,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import { supabase } from "./supabase-client";

interface SupabaseContextType {
  user: User | null;
}

const SupabaseContext = createContext<SupabaseContextType | undefined>(
  undefined
);

const SupabaseProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [user, setUser] = useState<User | null>(null);

  useEffect(() => {
    const sup = supabase.auth.onAuthStateChange((event, session) => {
      if (!!session) {
        setUser(session.user);
      } else {
        setUser(null);
      }
    });

    return () => sup.data.subscription.unsubscribe();
  }, []);

  return (
    <SupabaseContext.Provider value={{ user }}>
      {children}
    </SupabaseContext.Provider>
  );
};

const useSupabaseAuth = () => {
  const context = useContext(SupabaseContext);
  if (context === undefined) {
    throw new Error("useSupabaseAuth must be used within a SupabaseProvider");
  }
  return context;
};

export { SupabaseProvider, useSupabaseAuth };
