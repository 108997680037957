import { useQuery } from "@tanstack/react-query";
import { supabase } from "../supabase/supabase-client";

export const useLogeMembers = () => {
  const getAllMembers = async () => {
    const { data, error } = await supabase.from("loge_members").select("*");
    if (error) throw error;
    return data;
  };

  return useQuery({
    queryKey: ["members"],
    queryFn: getAllMembers,
    staleTime: Infinity,
  });
};
