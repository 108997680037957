import React, { useCallback, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { useNavigate, useParams } from "react-router-dom";
import { useDeleteEvent, useGetEvent, useUpdateEvent } from "../api/events-api";
import { queryClient } from "../api/queryClient";
import EventForm, { EventFormValues } from "../components/events/EventForm";
import { assertIsDefined } from "../utils/assertion";

const EditEvent: React.FC = () => {
  const { eventId } = useParams();
  const { data: event, isLoading } = useGetEvent(eventId);
  const { mutateAsync: updateEvent, isLoading: isUpdating } = useUpdateEvent();
  const { mutateAsync: deleteEvent, isLoading: isDeleting } = useDeleteEvent();
  const navigate = useNavigate();
  const [showDeleteConfirmation, setShowDelete] = useState(false);

  const onSubmit = useCallback(
    async (formValues: EventFormValues) => {
      assertIsDefined(eventId);
      await updateEvent({ eventId: Number(eventId), formValues });
      queryClient.invalidateQueries();
    },
    [eventId, updateEvent]
  );

  const onDeleteEvent = useCallback(async () => {
    if (eventId) {
      assertIsDefined(eventId);
      await deleteEvent(Number(eventId));
      queryClient.invalidateQueries(["events"]);
      navigate("/");
    }
  }, [deleteEvent, eventId, navigate]);

  return (
    <div className="p-4 mx-auto max-w-md">
      <h2 className="text-2xl mb-4">Edit Event</h2>
      <div
        onClick={() => navigate(`/vote/${eventId}`)}
        className="underline text-teal-700 tracking-wider"
      >
        Afstemning
      </div>
      {event && !isLoading && !isUpdating && !isDeleting && (
        <EventForm
          initialValues={{
            title: event.title,
            date: event.date,
            emoji: event.emoji,
            host: event.host,
            isConfirmed: event.is_confirmed,
            showVoteLink: event.show_vote_link,
          }}
          onSubmit={onSubmit}
        />
      )}
      <div className="flex flex-col items-center mt-10">
        <button
          className="text-xs font-medium text-red-400 mb-4"
          onClick={() => setShowDelete(true)}
        >
          Delete
        </button>
        {showDeleteConfirmation && (
          <button
            className="text-sm bg-red-400 text-white font-md px-3 py-2 rounded"
            onClick={onDeleteEvent}
          >
            Slet event
          </button>
        )}
      </div>
    </div>
  );
};

export default EditEvent;
