import React, { FormEvent, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { supabase } from "../supabase/supabase-client";

type OnClickEvent = React.MouseEvent<HTMLDivElement, MouseEvent>;

const LoginPage: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");

  const from = location.state?.from?.pathname || "/";

  const handleSignIn = async (event: OnClickEvent | FormEvent) => {
    event.preventDefault();
    try {
      await supabase.auth.signInWithPassword({ email, password });
      navigate(from, { replace: true });
    } catch (error) {
      setError("Invalid email or password");
      console.error("Error signing in:", error);
    }
  };

  return (
    <div className=" items-center justify-center bg-gray-100">
      <div className="max-w-md p-6 bg-white shadow-lg rounded-lg">
        <h1 className="text-2xl font-semibold mb-4">Login</h1>
        <form onSubmit={(event) => handleSignIn(event)}>
          <div className="mb-4">
            <label className="block mb-1">Email:</label>
            <input
              className="w-full p-2 border rounded-md"
              type="email"
              value={email}
              name="email"
              onChange={(e) => setEmail(e.target.value)}
              autoComplete="email"
            />
          </div>
          <div className="mb-4">
            <label className="block mb-1">Password:</label>
            <input
              className="w-full p-2 border rounded-md"
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          <button
            className="w-full bg-blue-500 text-white p-2 rounded-md"
            onClick={(event) => handleSignIn(event)}
          >
            Sign In
          </button>
        </form>
        {error && <p className="text-red-500 mt-2">{error}</p>}
      </div>
      <div className="flex max-w-md">
        <Link
          className="w-full bg-green-400 text-white py-2 px-4 rounded-md text-center hover:bg-blue-600 transition duration-300"
          to="/"
        >
          Back
        </Link>
      </div>
    </div>
  );
};

export default LoginPage;
