import { DetailedHTMLProps, FC, HTMLAttributes, useMemo } from "react";
import { useLogeMembers } from "../api/user-api";
import { LogeMember } from "../types/entities";

interface Props
  extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  userId: LogeMember["id"];
  showName?: boolean;
  bgColor?: BgColor;
  onClick?: () => void;
  selected?: boolean;
  size?: AvatarSize;
}

type AvatarSize = "xs" | "sm" | "md";
type BgColor = "slate" | "green" | "yellow";

const AvatarCircle: FC<Props> = ({
  userId,
  showName = true,
  onClick,
  selected = false,
  bgColor = "slate",
  size = "md",
  ...rest
}) => {
  const { data: members } = useLogeMembers();
  const member = members?.find((m) => m.id === userId);
  const circleSize = useMemo(() => {
    if (size === "xs") return 18;
    if (size === "sm") return 30;
    return 48;
  }, [size]);

  const emojiSize = useMemo(() => {
    if (size === "xs") return "xs";
    if (size === "sm") return "xl";
    return "3xl";
  }, [size]);

  const setBgColor = useMemo(() => {
    switch (bgColor) {
      case "green":
        return "bg-green-200";
      case "slate":
        return "bg-slate-200";
      case "yellow":
        return "bg-yellow-100";
    }
  }, [bgColor]);

  return (
    <div className={`flex flex-col items-center select-none`} {...rest}>
      <div
        onClick={onClick}
        className={`flex ${setBgColor} justify-center items-center rounded-full ${
          onClick ? "cursor-pointer" : ""
        } ${selected ? "border-2 border-green-500" : "border-0"}`}
        style={{
          WebkitTapHighlightColor: "transparent",
          width: circleSize,
          height: circleSize,
        }}
      >
        <div className={`text-${emojiSize}`}>{member?.emoji}</div>
      </div>
      {showName && (
        <span className="text-[11px]  text-slate-400 mt-1">{member?.name}</span>
      )}
    </div>
  );
};

export default AvatarCircle;
