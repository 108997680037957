import dayjs from "dayjs";
import React from "react";

interface NumberProps {
  date?: string;
  loading?: boolean;
}

const Number: React.FC<NumberProps> = ({ date, loading = false }) => {
  const daysUntil = dayjs(date)
    .startOf("day")
    .diff(dayjs().startOf("day"), "d");

  return (
    <div className="flex">
      <div className=" pr-2 text-xl text-gray-500 flex items-center justify-end ">
        Vi ses om
      </div>
      <div
        className={`rounded-xl shadow-inner bg-gray-200  border border-gray-300 font-semibold text-gray-700 text-x flex items-center justify-center font-mono`}
        style={{ height: 78, width: 80, fontSize: 40 }}
      >
        {loading ? "--" : daysUntil}
      </div>
      <div className="flex-1 pl-2 text-xl  text-gray-500  flex items-center ">
        {daysUntil === 1 ? "dag" : "dage"}
      </div>
    </div>
  );
};

export default Number;
